import d from "./OuterDiskatBigTree.jpg";
import e from "./GCRoBT.jpg";
import f from "./badlands1.jpg";
import g from "./Badlands2.jpg";
import h from "./CrazyHorse.jpg";
import i from "./DevilsTower.jpg";

const photos = {
    d, e, f, g, h, i
}
export default photos;